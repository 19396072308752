<template>
  <div class="box">
    <div class="main">
      <div>
        <div style="
                    margin-left: 40px;
                    margin-bottom: 30px;
                    position: relative;
                    font-size: 16px;
                  "
        >
          <div class="all">
            <el-checkbox
                v-model="checkAll"
                @change="handleCheckAllChange"
            >全选
            </el-checkbox
            >
          </div>
          <div
              v-for="(item, index) in arr"
              :key="index"
              style="display: flex"
          >
            <div
                :class="{ item_p_0: index == 0 }"
                style="margin-right: 20px"
            >
              <el-checkbox
                  @change="handleCheckChange($event, item, index)"
                  :checked="calculation(item)"
                  v-model="selectItemCheck[index]"
                  ref="leftCheck"
              ></el-checkbox>
            </div>
            <div
                v-for="(row, i) in item"
                :key="i"
                style="display: flex"
            >
              <div :style="i == 0 ? 'width: 80px;' : ''">
                <p
                    style="font-size: 16px; margin-bottom: 6px"
                    :class="{ item_p_0: index == 0 }"
                >
                  {{ i == 0 ? row.ModuleName : "" }}
                </p>
              </div>
              <div class="row_div">
                <p>{{ index == 0 ? row.FunctionName : "" }}</p>
                <el-checkbox
                    v-model="row.flag"
                    @change="ChangeJurisdiction($event, row)"
                ></el-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-left: 40px">
          <el-radio-group v-model="DataScope">
            <el-radio label="1">本组织</el-radio>
            <el-radio label="2">本项目</el-radio>
            <el-radio label="3">本中心</el-radio>
            <el-radio label="4">本人</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import administrators from "@/api/administrators";
import {local} from "@/utils/storage";

export default {
  data() {
    return {
      show: false,
      flag: false,
      checkAll: false,
      tableData: [],
      Moduleslist: [],
      Functionslist: [],
      Name: "",
      organization: [],
      OrganizationId: "",
      permissions: [],
      DataScope: '1',
      type: 1,
      row: {},
      list: [],
      arr: [],
      total: 0,
      listQuery: {
        Key: "",
        page: 1,
        rows: 20,
      },
      selectItemCheck: [],
    }
  },
  created() {
    this.GetMap()
    window.addEventListener('message', async (e) => {
      const {data} = e
      if (data.type === 'auth') {
        let {access_token, OrganizationId, action} = data.info
        access_token = access_token.split(' ')[1]
        local.set("Token", access_token);
        this.OrganizationId = OrganizationId
        if (action === 'CREATE') {
          this.type = 1
        } else if (action === 'EDIT') {
          this.type = 2
        }
      } else if (data.type === 'role') {
        let {roleId, roleName} = data.info
        this.Name = roleName
        this.row.Id = roleId
        if (this.type === 2) {
          await this.preAuth({Id: roleId, BuyerId: this.OrganizationId, Name: roleName})
          this.EditJurisdiction(roleId)
        }
      } else if (data.type === 'confirm') {
        this.determine()
      }
    })
  },

  methods: {
    async preAuth(data) {
      let res = await administrators.preAuth(data)
      console.log("=>(setting.vue:135) res", res);
    },
    calculation(item) {
      let result = null
      result = item.every(row => {
        return row.flag == true
      })
      return result
    },

    handleCheckChange(e, item, index) {
      if (e) {
        item.forEach(row => {
          // if(!row.flag){
          this.permissions.push({
            ModuleId: row.ModuleId,
            FunctionId: row.FunctionId,
            index: index
          })
          // };
          this.$set(row, 'flag', true)
        })
      } else {
        item.forEach(row => {
          this.$set(row, 'flag', false)
        })
        let ModuleId = item[0].ModuleId
        // console.log(this.permissions)
        this.permissions = this.permissions.filter(item => {
          return item.ModuleId !== ModuleId
        })
      }
    },

    //全选
    handleCheckAllChange(val) {
      this.checkAll = val
      if (val) {
        // this.$refs.leftCheck.forEach((item,index)=>{
        //   console.log(item,'checked');
        // })
        this.show = true
        this.permissions.length = 0
        this.arr.forEach((item, index) => {
          item.forEach(row => {
            this.permissions.push({
              ModuleId: row.ModuleId,
              FunctionId: row.FunctionId,
            })
            this.$set(row, 'flag', true)
            this.$set(row, 'index', index)
          })
        })
      } else {
        // this.show=false
        this.arr.forEach(item => {
          item.forEach(row => {
            this.$set(row, 'flag', false)
          })
        })
        this.permissions = []
      }
    },
    //确定
    determine() {
      // 新增
      if (this.type === 1) {
        let data = {
          BuyerId: this.OrganizationId,
          Name: this.Name,
          Id: this.row.Id
        }

        const parameter = {
          role: {
            OrganizationId: this.OrganizationId,
            Name: this.Name,
            DataScope: this.DataScope,
            Id: this.row.Id
          },
          permissions: this.permissions
        }
        administrators.createAuth(parameter).then(res => {
          if (res.data.Status === 1) {
            let data = {
              type: 'state',
              info: {
                success: true,
                message: '',
                result: {}
              }
            }
            window.parent.postMessage(data, '*')
          } else {
            let data = {
              type: 'state',
              info: {
                success: false,
                message: res.data.Message,
                result: {}
              }
            }
            window.parent.postMessage(data, '*')
          }
        })
      } else {
        // 编辑
        const parameter = {
          role: {
            // OrganizationId:this.OrganizationId,
            Name: this.Name,
            DataScope: this.DataScope,
            Id: this.row.Id
          },
          permissions: this.permissions
        }
        administrators.EditJurisdictionData(parameter).then(res => {
          if (res.data.Status == 1) {
            let data = {
              type: 'state',
              info: {
                success: true,
                message: '',
                result: {}
              }
            }
            window.parent.postMessage(data, '*')
          } else {
            let data = {
              type: 'state',
              info: {
                success: false,
                message: res.data.Message,
                result: {}
              }
            }
            window.parent.postMessage(data, '*')
          }
        })
      }
    },
    // //组织
    // NoPagingOrganization(){
    //     const parameter={
    //           Key:"",
    //     }
    //     administrators.NoPagingOrganizationData(parameter).then(res=>{
    //          console.log(res,"组织");
    //          if(res.data.Status==1){
    //             this.organization=res.data.Data
    //          }else{
    //           alert(res.data.Message)
    //         }
    //     })
    // },
    //打开角色弹框
    addRole() {
      //  this.OrganizationId=""
      this.Name = ""
      this.DataScope = '1'
      this.type = 1
      this.arr.forEach(item => {
        item.forEach((row, index) => {
          this.selectItemCheck[index] = false
          this.$set(row, 'flag', false)
        })
      })
      //  this.NoPagingOrganization()
      this.permissions = [];
    },
    //复选框回调
    ChangeJurisdiction(e, item) {
      if (e) {
        let obj = {
          ModuleId: item.ModuleId,
          FunctionId: item.FunctionId,
        }
        this.permissions.push(obj)
      } else {
        this.permissions = this.permissions.filter(m => {
          if (!(item.ModuleId == m.ModuleId && item.FunctionId == m.FunctionId)) {
            return m
          }
        })
      }
    },
    //权限矩阵
    GetMap() {
      administrators.GetMapData().then(res => {
        if (res.data.Status == 1) {
          res.data.Data.Modules.forEach((item) => {
            res.data.Data.Functions.forEach((row) => {
              this.list.push({
                ModuleName: item.ModuleName,
                ModuleId: item.ModuleId,
                FunctionName: row.FunctionName,
                FunctionId: row.FunctionId,
                flag: false,
              })
              if (this.list.length == res.data.Data.Functions.length) {
                this.arr.push(this.list)
                this.list = []
              }
            })

          })
        } else {
          alert(res.data.Message)
        }
      })
    },
    cancel() {
      //需要将permission制空，否则每次点击新增角色的时候重复会push数据，
      this.permissions = [];
    },
    //打开编辑
    EditJurisdiction(id) {
      let flag = null
      const parameter = {
        id,
      }
      administrators.GetRoleData(parameter).then(res => {
        this.permissions = res.data.Data.permissions
        this.Name = res.data.Data.role.Name
        this.DataScope = JSON.stringify(res.data.Data.role.DataScope);
        this.arr.forEach((item) => {
          item.forEach(row => {
            this.$set(row, 'flag', false)
            this.permissions.forEach(m => {
              if (row.ModuleId == m.ModuleId && row.FunctionId == m.FunctionId) {
                this.$set(row, 'flag', true)
              }
            })
          })
        })
        flag = this.arr.every(item => {
          return item.every(row => {
            return row.flag
          })
        })
      })
    },
    //删除
    DeleteJurisdiction(row) {
      const parameter = []
      parameter.push(row.Id)
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        administrators.DeleteJurisdictionData(parameter).then(res => {
          if (res.data.Status == 1) {
            this.tableData = this.tableData.filter(item => {
              return item.Id != row.Id
            })
          } else {
            alert(res.data.Message)
          }
        })
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      })
    }
  },
  watch: {
    "arr": {
      handler(newVal, oldVal) {
        if (newVal) {
          this.arr.forEach((item, index) => {
            // if(item)
            this.selectItemCheck[index] = item.every((val) => {
              return val.flag
            })
          })
          this.checkAll = this.selectItemCheck.every((item) => item)
        }
      },
      deep: true,
    }
  }
}
</script>

<style lang='scss' scoped>

.all {
  position: absolute;
}

.item_p_0 {
  margin-top: 22px;
}

.row_div {
  text-align: center;
  width: 60px;
  font-size: 16px;
}

::v-deep .el-checkbox__label {
  font-size: 16px;
  margin-left: 10px;
}
</style>
